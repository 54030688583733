"use client";

import { Box, useColorModeValue } from "@chakra-ui/react";
import { usePathname } from "next/navigation";
import React, { useContext, useEffect, useMemo } from "react";
import { useCallback, useLayoutEffect, useRef, useState } from "react";

export interface PageProgressProps {
  delay?: number;
  isNavigating?: boolean;
}

export function PageProgress({
  isNavigating = false,
  delay = 0,
}: PageProgressProps) {
  const barRef = useRef<HTMLDivElement>(null);
  const progressRef = useRef(1);
  const showFrameRef = useRef<number | null>(null);
  const hideFrameRef = useRef<number | null>(null);
  const delayTimerRef = useRef<number | null>(null);

  const progress = useCallback((progress?: number | string) => {
    let value = progressRef.current;
    let changed = false;

    if (typeof progress === "string") {
      if (progress.startsWith("+") || progress.startsWith("-")) {
        value += Number.parseFloat(progress);
        changed = true;
      }
    } else if (typeof progress === "number") {
      value = progress;
      changed = true;
    }

    if (changed) {
      // update value
      value = Math.max(Math.min(value, 1), 0);
      progressRef.current = value;

      const bar = barRef.current;
      if (bar) {
        bar.style.transform = `scaleX(${value})`;
      }
    }
    // return new value
    return value;
  }, []);

  const show = useCallback(
    function show(delay?: number) {
      const bar = barRef.current;
      if (!bar) {
        return;
      }

      if (delay) {
        // check if already waiting
        if (!delayTimerRef.current) {
          delayTimerRef.current = window.setTimeout(show, delay);
        }
        return;
      }

      // cancel any fade
      if (hideFrameRef.current) {
        cancelAnimationFrame(hideFrameRef.current);
        hideFrameRef.current = null;
      }

      bar.style.opacity = "1";
      progress(0);

      const loop = () => {
        // update progress
        const currentProgress = progressRef.current;
        progress(`+${0.05 * (1 - Math.sqrt(currentProgress)) ** 2}`);

        // update next fade frame
        showFrameRef.current = requestAnimationFrame(loop);
      };

      loop();
    },
    [progress],
  );

  const hide = useCallback(() => {
    const bar = barRef.current;
    if (!bar) {
      return;
    }

    // cancel any progress
    if (showFrameRef.current) {
      cancelAnimationFrame(showFrameRef.current);
      showFrameRef.current = null;
    }

    if (delayTimerRef.current) {
      clearTimeout(delayTimerRef.current);
      delayTimerRef.current = null;
      return;
    }

    const loop = () => {
      // update progress
      if (progress("+.1") >= 1) {
        const opacity = Number.parseFloat(bar.style.opacity) - 0.05;
        bar.style.opacity = opacity.toString();

        // check if faded out to finish
        if (opacity <= 0) {
          return;
        }
      }

      // update next fade frame
      hideFrameRef.current = requestAnimationFrame(loop);
    };

    loop();
  }, [progress]);

  useLayoutEffect(() => {
    if (!isNavigating) {
      return;
    }

    show(delay);
    return () => hide();
  }, [isNavigating, delay, hide, show]);

  return (
    <Box
      position="fixed"
      left={0}
      right={0}
      top={0}
      zIndex={1000}
      ref={barRef}
      height="3px"
      boxShadow="0 0 10px rgb(0, 0, 0, 0.6);"
      bg={useColorModeValue("brand.500", "brand.200")}
      transform="scaleX(0)"
      transformOrigin="left"
      opacity={0}
      willChange="transform, opacity"
      pointerEvents="none"
    />
  );
}

export type PageProgressContextType = {
  isNavigating: boolean;
  setIsNavigating: (isNavigating: boolean) => void;
  navigate: () => void;
};

export const PageProgressContext = React.createContext<PageProgressContextType>(
  {
    isNavigating: false,
    setIsNavigating: () => {},
    navigate: () => {},
  },
);

export function usePageProgress() {
  return useContext(PageProgressContext);
}

export function PageProgressProvider({
  children,
  ...props
}: Omit<PageProgressProps, "isNavigating"> & {
  children: React.ReactNode;
}) {
  const [isNavigating, setIsNavigating] = useState(false);
  const pathname = usePathname();

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    setIsNavigating(false);
  }, [pathname]);

  const value = useMemo(
    () => ({
      isNavigating,
      setIsNavigating,
      navigate: () => setIsNavigating(true),
    }),
    [isNavigating],
  );

  return (
    <PageProgressContext.Provider value={value}>
      <PageProgress {...props} isNavigating={isNavigating} />
      {children}
    </PageProgressContext.Provider>
  );
}
