import type { SessionUser } from "@/lib/auth/util";
import type { Optional, Serialized } from "@/lib/util";
import React, { useContext, useMemo } from "react";

export interface AuthContextType {
  user: Serialized<Optional<SessionUser>>;
}

export const AuthContext = React.createContext<AuthContextType>({
  user: null,
});

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({
  children,
  user,
}: {
  children: React.ReactNode;
  user: Serialized<Optional<SessionUser>>;
}) {
  const value = useMemo(
    () => ({
      user,
    }),
    [user],
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
